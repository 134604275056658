import {Component} from 'react';
import ConfigFile from '../../datasets/config_file';
import "./GameViews/gameViews.css";

class TimelineHorizontal extends Component {
    constructor(props) {
      super(props); 
      this.gameInfo = this.props.gameInfo();
      this.state={
        innerWidth: 0,
        rerender: false,
        screenWidth: 0,
        nbrMonths: 0,
      }
      this.resize();
    }

    resize() {
      window.addEventListener("resize", this.resize.bind(this))
      this.state.innerWidth = window.innerWidth;
    }
  
    setTopMargins(){
    
      let leftAdjust = -11;
      let lineSpacing = 15;
      let offset = 1;
      this.bgnTextClass = "timeline-bgn-game-text";
      this.endTextClass = "timeline-end-game-text";
      if (this.props.screenSize != "wide"){
           this.bgnTextClass = this.bgnTextClass + " small-text";
           this.endTextClass = this.endTextClass + " small-text";
      }
              
      this.line0Left = lineSpacing + offset + leftAdjust + "%";
      this.line1Left = (lineSpacing * 2) + offset + leftAdjust + "%";
      this.line2Left = (lineSpacing * 3) + offset + leftAdjust + "%";
      this.line3Left = (lineSpacing * 4) + offset + leftAdjust + "%";
      this.line4Left = (lineSpacing * 5) + offset + leftAdjust + "%"; 
      this.line5Left = (lineSpacing * 6) + offset + leftAdjust + "%";
      this.line6Left = (lineSpacing * 7) + offset + leftAdjust + "%";
    
      this.number0Left = lineSpacing + leftAdjust + "%";
      this.number1Left = (lineSpacing * 2) + leftAdjust + "%";
      this.number2Left = (lineSpacing * 3) + leftAdjust + "%";
      this.number3Left = (lineSpacing * 4) + leftAdjust + "%";
      this.number4Left = (lineSpacing * 5) + leftAdjust + "%";
      this.number5Left = (lineSpacing * 6) + leftAdjust + "%";
      this.number6Left = (lineSpacing * 7) + leftAdjust + "%";
    }

    determinePctComplete(){  
      let initialOffset = 0;
      let dynamicAdjust = 0;
      if (this.props.screenSize == "wide"){
          initialOffset = 3;
          // wide screen max is 1536
          dynamicAdjust = ((1536 - this.state.innerWidth) / 100 ).toFixed(2);
  
      }else{
          // narrow screen max is 999
          dynamicAdjust = ((999 - this.state.innerWidth) / 90).toFixed(2);
          initialOffset = 5;
      }
             
      let totalGameMonths = ConfigFile.gameStartItems.totalNbrGameMonths;
      let monthsDiff = totalGameMonths - this.gameInfo.gameData.gameNbrMonths;
    //  monthsDiff = 120;
      this.varLength = Math.trunc((monthsDiff * .25) - initialOffset   
          - dynamicAdjust) + "%";
      let test = ((monthsDiff * .25) - initialOffset   
      - dynamicAdjust) + "%";
      this.state.nbrMonths = monthsDiff;
      this.state.screenWidth = this.state.innerWidth;
    }

    determineIfNewYear(){
      let numerator = +this.state.nbrMonths;
      let remainder = 99;
      if (this.gameInfo.gamePhase === "1"){
          remainder = numerator % 12;
      }else{
          //  this logic determines if a new year was encountered due to change in quarter
          for (let i=0; i<=2; i++){
              numerator -= ( i );
              remainder = numerator % 12;
              if (remainder === 0){
                  break;
              }
          }
      }
    
      this.year = false;
      if (remainder === 0){
        this.class += " spinTrainAround";
        const nbrYears = numerator / 12;
        let sText = " Has";
        if (nbrYears > 1){
           sText = "s Have";
        }
        this.timeText = nbrYears + " Game Year" + sText + " Passed";
        this.year = true;
        if (nbrYears < 12 || nbrYears > 22){
          this.trainTextClass = "train-text-center font-weight-bold";
        }else{
          this.trainTextClass = "train-text-left font-weight-bold";
        }
        
      }
    }

    render() { 
      if (this.state.innerWidth != this.state.screenWidth ||
          this.state.nbrMonths != this.monthsDiff){
        this.setTopMargins();
        this.class = "timeline-train-position timeline-train-img";
        this.determinePctComplete();
        this.determineIfNewYear();
      }
                       
    return <>
            <div className="">
                <div className={this.bgnTextClass}>Nbr Years Elapsed</div>
                <div className="horizontal-timeline" ></div>
                <div className="timelineNumberPlacement text-center" style={{left: this.number0Left}}>0</div>
                <div className="timelineVerticalLine" style={{left: this.line0Left}}></div>
                <div className="timelineNumberPlacement text-center" style={{left: this.number1Left}}>5</div>
                <div className="timelineVerticalLine" style={{left: this.line1Left}}></div>
                <div className="timelineNumberPlacement text-center" style={{left: this.number2Left}}>10</div>
                <div className="timelineVerticalLine" style={{left: this.line2Left}}></div>
                <div className="timelineNumberPlacement text-center" style={{left: this.number3Left}}>15</div>
                <div className="timelineVerticalLine" style={{left: this.line3Left}}></div>
                <div className="timelineNumberPlacement text-center" style={{left: this.number4Left}}>20</div>
                <div className="timelineVerticalLine" style={{left: this.line4Left}}></div>
                <div className="timelineNumberPlacement text-center" style={{left: this.number5Left}}>25</div>
                <div className="timelineVerticalLine" style={{left: this.line5Left}}></div>
                <div className="timelineNumberPlacement text-center" style={{left: this.number6Left}}>30</div>
                <div className="timelineVerticalLine" style={{left: this.line6Left}}></div>
                <img src="./assets/timeline-train.png" style={{left: this.varLength}}
                          className={this.class} alt="train"
                    />
                <div className={this.endTextClass}>End Game</div>
                                     
            </div>
            {this.year ?
              <div className={this.trainTextClass}>
                   {this.timeText}
              </div>
            :
              null
            }
    </>
  }

}

//<div className="timelineFill" style={{width: this.varLength}} ></div>

 
export default TimelineHorizontal;