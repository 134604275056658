import {Component} from 'react';
import {standardNumberFormat} from '../../objects/CommonUse/double_functions';



class FiveColumnDisplayCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
    }


    render() {
        console.log("category, activity: ", this.props.tableInfo.category, " ", this.props.tableInfo.activityName);      
    return <>
        <tr>
            <td className="p-0">{this.props.tableInfo.category}</td>
            <td className="p-0 text-left">{this.props.tableInfo.activityName}</td>
            <td className="text-center p-0">{standardNumberFormat(this.props.tableInfo.nbrTimesPlayed)}</td>
            <td className="text-center p-0">{standardNumberFormat(this.props.tableInfo.nbrPlayersPlayed)}</td>
            <td className="text-center p-0">{standardNumberFormat(this.props.tableInfo.maxLevel)}</td>
        </tr>

    </>
    }
}

export default FiveColumnDisplayCard;